/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "verwarming/verwarming-8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "verwarming/verwarming-8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(
      relativePath: { eq: "verwarming/verwarming-9.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Radiatoren in alle soorten en maten`,
  textWithLink: () => (
    <div className='mb-4x'>
      <p>
        Centrale verwarming mag tegenwoordig gezien worden en hoeft niet langer
        verborgen te worden achter gordijnen of banken. Designradiatoren zorgen
        immers niet alleen voor warmte in huis, maar zijn ook een mooie
        aanvulling op uw interieur. De mogelijkheden zijn eindeloos: van
        klassiek naar modern en van robuust tot minimalistisch.
      </p>
    </div>
  ),
};
const textBottom = {
  header: `Welke radiator past bij u?`,
  textWithLink: () => (
    <>
      <p>
        Radiatoren zijn verkrijgbaar in talloze kleuren, maten, afmetingen en
        designs. Het meest voorkomende type is de paneelradiator, deze heeft een
        vlakke voorplaat en zorgt voor een snelle warmte-afgifte. Daarnaast kan
        er ook gekozen worden voor een ledenradiator voor een robuuste,
        authentieke uitstraling. Deze radiator is uitstekend geschikt voor hoge
        ruimtes waar veel warmte nodig is. Wanneer er geen aansluiting is voor
        warm water, is een elektrische radiator een handige oplossing.
        Elektrische radiatoren worden vaak toegepast als bijverwarming in
        bijvoorbeeld de badkamer of recreatiewoning. Voor wie veel waarde hecht
        aan een mooie vormgeving, is de designradiator een uitkomst.
        Verkrijgbaar in bijzondere vormen en materialen: een ware eyecatcher
        voor ieder interieur.
      </p>
      <p>Komt u er niet uit? Bel ons en krijg advies op maat.</p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
      <AniLink fade to='/verwarming' className='ml-md-4x'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Radiatoren = ({ data, path, location }) => {
  const seo = {
    title: `Radiatoren`,
    description: `Onze deskundige installateur komt graag bij u thuis langs om uw wensen en de mogelijkheden voor uw radiatoren te bespreken.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    textFirst: true,
    noSpace: true,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Advies & installatie',
    textWithLink: () => (
      <>
        <p>
          Onze deskundige installateur komt graag bij u thuis langs om uw wensen
          en de mogelijkheden voor uw situatie te bespreken. Hij berekent exact
          hoeveel radiatoren er nodig zijn voor een aangenaam binnenklimaat en
          kan u tevens meer vertellen over de nieuwste trends en verschillende
          modellen. Zo bent u altijd verzekerd van optimaal wooncomfort.
        </p>
        <p>
          Heeft u eenmaal uw keuze gemaakt? Dan zorgen onze installateurs
          vervolgens voor een zeer professionele en nette installatie. Meer
          weten over radiatoren of een vrijblijvende offerte aanvragen? Neem dan
          gerust{' '}
          <AniLink to='/contact' fade className='text--underline'>
            contact
          </AniLink>{' '}
          met ons op.
        </p>
      </>
    ),
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw Radiatoren`,
    pitchLine: `Laat al uw Radiatoren over aan onze professionals.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

Radiatoren.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Radiatoren;
